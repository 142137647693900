import React from 'react'
import djg from './touchIcon.png'

function WebImg() {
  return (
    <>
            <div className='webimg'>
            <img src={djg} alt='DGJ'></img>

            <h2>Create. Join. Grow.</h2>
            </div>
    </>
  )
}

export default WebImg